import CardPropertiesChips from "@/components/dashboard/cards/CardPropertiesChips.vue";
import CardPropertiesCompare from "@/components/dashboard/cards/CardPropertiesCompare.vue";
import CardPropertiesField from "@/components/dashboard/cards/CardPropertiesField.vue";
import CardPropertiesGraph from "@/components/dashboard/cards/CardPropertiesGraph.vue";
import CardPropertiesList from "@/components/dashboard/cards/CardPropertiesList.vue";
import CardPropertiesMultiField from "@/components/dashboard/cards/CardPropertiesMultiField.vue";
import CardPropertiesOverview from "@/components/dashboard/cards/CardPropertiesOverview.vue";
import CardPropertiesRiskRibitMap from "@/components/dashboard/cards/CardPropertiesRiskRibitMap.vue";
import CardPropertiesTsua from "@/components/dashboard/cards/CardPropertiesTsua.vue";
import chipsCardResolver from "./resolvers/chipsCardResolver";
import compareCardResolver from "./resolvers/compareCardResolver";
import fieldCardResolver from "./resolvers/fieldCardResolver";
import graphCardResolver from "./resolvers/graphCardResolver";
import listCardResolver from "./resolvers/listCardResolver";
import multifieldCardResolver from "./resolvers/multiFieldCardResolver";
import overviewCardResolver from "./resolvers/overviewCardResolver";
import riskRibitMapCardResolver from "./resolvers/riskRibitMapCardResolver";
import tsuaCardResolver from "./resolvers/tsuaCardResolver";

export default function install(Vue) {
  let cardResolvers = [
    fieldCardResolver,
    multifieldCardResolver,
    listCardResolver,
    chipsCardResolver,
    graphCardResolver,
    tsuaCardResolver,
    overviewCardResolver,
    compareCardResolver,
    riskRibitMapCardResolver
  ];

  const baseCard = {
    width: 1,
    height: 1,
    class: "pa-2",
    title: undefined
  };

  const cardsDefinitions = [
    {
      type: "HTML",
      editor: {
        hasClass: true,
        hasBackground: true,
        hasTitle: false,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: false
      },
      createForContext() {
        return {
          ...baseCard,
          type: "HTML",
          body: "HTML card"
        };
      }
    },
    {
      type: "FIELD",
      editor: {
        hasClass: true,
        hasBackground: true,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesField
      },
      createForContext(context, field) {
        return {
          ...baseCard,
          type: "FIELD",
          field: field,
          aggregation: "first",
          context: context
        };
      }
    },
    {
      type: "MULTIFIELD",
      editor: {
        hasClass: true,
        hasBackground: true,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: false,
        component: CardPropertiesMultiField
      },
      createForContext(context, field) {
        return {
          ...baseCard,
          type: "MULTIFIELD",
          fields: [
            {
              field: field,
              aggregation: "first",
              conditions: [],
              context: context,
              title: "",
              subTitle: ""
            }
          ]
        };
      }
    },
    {
      type: "LIST",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesList
      },
      createForContext() {
        return {
          ...baseCard,
          type: "LIST",
          context: "mutzarim_before",
          groupBy: "kod_mezahe_yatzran",
          title: "תוכניות לפי חברות",
          fields: [
            {
              field: "shem_yatzran",
              aggregation: "first"
            },
            {
              field: "mispar_heshbon",
              display: "מספר תוכניות",
              aggregation: "distinct"
            },
            {
              field: "total_saving",
              aggregation: "sum"
            }
          ],
          width: 2,
          height: 2
        };
      }
    },
    {
      type: "COMPARE",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: false,
        hasContextAndConditions: false,
        component: CardPropertiesCompare
      },
      createForContext() {
        return {
          ...baseCard,
          type: "COMPARE",
          contextA: "mutzarim_reference",
          contextB: "mutzarim_before",
          groupBy: "kod_mezahe_yatzran",
          groupByTitle: "",
          title: "דוח תקופה",
          fields: [
            {
              field: "taarich_nechonut",
              aggregation: "first",
              display: "תאריך"
            },
            {
              field: "mispar_heshbon",
              display: "מספר תוכניות",
              aggregation: "distinct"
            },
            {
              field: "total_saving",
              aggregation: "sum",
              display: "חסכון"
            },
            {
              field: "total_nazil",
              aggregation: "sum",
              display: "כסף נזיל"
            },
            {
              field: "hafkada_chodshei",
              aggregation: "sum",
              display: "הפקדה חודשית"
            },
            {
              field: "dmei_nihul_chodshei",
              aggregation: "sum",
              display: "דמי ניהול חודשי"
            },
            {
              field: "ramat_sikun_menayot",
              aggregation: "average",
              display: "אחוז מניות"
            }
          ],
          width: 2,
          height: 2
        };
      }
    },
    {
      type: "CHIPS",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesChips
      },
      createForContext() {
        return {
          ...baseCard,
          type: "CHIPS",
          context: "mutzarim_before",
          groupBy: "kod_mezahe_yatzran",
          title: "תוכניות לפי חברות",
          fields: [
            {
              field: "shem_yatzran",
              aggregation: "first"
            }
          ],
          width: 2,
          height: 1
        };
      }
    },
    {
      type: "DOUGHNUT",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesGraph
      },
      createForContext() {
        return {
          ...baseCard,
          type: "DOUGHNUT",
          context: "mutzarim_before",
          title: "תוכניות לפי חברות",
          categoryField: "shem_yatzran",
          valueFields: [
            {
              field: "total_saving",
              aggregation: "sum"
            }
          ],
          width: 2,
          height: 2
        };
      }
    },
    {
      type: "BARCHART",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesGraph
      },
      createForContext() {
        return {
          ...baseCard,
          type: "BARCHART",
          context: "mutzarim_before",
          title: "תוכניות לפי חברות",
          categoryField: "shem_yatzran",
          valueFields: [
            {
              field: "total_saving",
              aggregation: "sum"
            }
          ],
          width: 2,
          height: 2
        };
      }
    },
    {
      type: "TSUA_MASLUL",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: false,
        hasContextAndConditions: true,
        component: CardPropertiesTsua
      },
      createForContext() {
        return {
          type: "TSUA_MASLUL",
          context: "mutzarim_before",
          class: "pa-3",
          width: 2,
          height: 2,
          threshold: 10,
          field: "tsua_mitztaberet",
          maxHistory: 36
        };
      }
    },
    {
      type: "OVERVIEW",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        // hasContextAndConditions: true,
        component: CardPropertiesOverview
      },
      createForContext() {
        return {
          type: "OVERVIEW",
          class: "pa-3",
          width: 2,
          height: 2,
          subject: "PRISHA_COST_OF_LIVING",
          title: "",
          subTitle: ""
        };
      }
    },
    {
      type: "RISK_RIBIT_MAP",
      editor: {
        hasClass: true,
        hasBackground: false,
        hasTitle: true,
        hasTooltip: true,
        hasLink: true,
        hasContextAndConditions: true,
        component: CardPropertiesRiskRibitMap
      },
      createForContext() {
        return {
          type: "RISK_RIBIT_MAP",
          class: "pa-3",
          width: 2,
          height: 2,
          title: "מפת הכסף שלך",
          context: "mutzarim_before",
          field: "tsua_shnatit_memuzaat_5_shanim"
        };
      }
    },
    {
      type: "EMBED",
      editor: {
        hasClass: false,
        hasBackground: false,
        hasTitle: false,
        hasTooltip: false,
        hasLink: true,
        hasContextAndConditions: false
      },
      createForContext() {
        return {
          type: "EMBED",
          width: 1,
          height: 1,
          url: ""
        };
      }
    },
    {
      type: "EMPTY",
      createForContext() {
        return {
          ...baseCard,
          type: "EMPTY"
        };
      }
    }
  ];

  let storeModule = new Vue({
    data() {
      return {};
    },

    computed: {
      dataContext() {
        return this.$store.dataContext;
      }
    },

    methods: {
      cardsForContext(context) {
        let contextDetails = this.dataContext.getContextType(context);
        let keyField = contextDetails ? contextDetails.keyField : undefined;

        let results = [];
        for (let cardDefinition of cardsDefinitions) {
          let card = cardDefinition.createForContext(context, keyField);
          if (card) {
            results.push(card);
          }
        }
        return results;
      },

      getCardEditor(card) {
        let def = cardsDefinitions.find((def) => def.type == card.type);
        return (def && def.editor) || undefined;
      },

      async resolve(context, card) {
        for (let resolver of cardResolvers) {
          if (resolver.supported(card)) {
            return resolver.resolveCard(
              context || this.dataContext.getFilteredContext(),
              card
            );
          }
        }

        return card;
      }
    }
  });

  return storeModule;
}
