import {
  dateField,
  lookupField,
  moneyField,
  percentField,
  weightedAverage,
  weightedPercentField
} from "./utils";

let mutzarimResolvers = {
  id: {
    display: "מזהה מוצר",
    getValue(item) {
      return `${item.index}:${item.mispar_heshbon}`;
    }
  },
  mispar_heshbon: { display: "מספר חשבון", ltr: true },
  polisa_str: { display: "מספר מערכת" },
  mispar_heshbon_and_maslul: {
    display: "מספר חשבון ומסלול",
    getValue(item) {
      
      const maslul = item.mispar_maslul || "";
      if (!maslul) {
        return item.mispar_heshbon;
      }
      const heshbonWithoutIndex = item.mispar_heshbon.split("[")[0];
      return `${heshbonWithoutIndex}-${maslul}`;
    }
  },

  sug_mutzar: lookupField(
    "סוג מוצר",
    {
      1: "ביטוח מנהלים",
      2: "קרן פנסיה",
      3: "קופת גמל",
      4: "קרן השתלמות",
      5: "ביטוח מנהלים",
      6: "ביטוח חיים",
      7: "ביטוח חיים",
      8: "ביטוח קולקטיב",
      9: "קופת גמל להשקעה"
    },
    {
      getValue(item) {
        if (item.sug_mutzar == 5) {
          return 1;
        } else {
          return item.sug_mutzar;
        }
      }
    }
  ),
  shm_kupa: { display: "שם קופה" },

  taarich_techilat_polisa: dateField("תאריך פתיחת תוכנית"),
  sug_tochnit_o_cheshbon: {
    display: "מעמד בתוכנית",
    displayValues: [
      { value: 1, display: "שכיר" },
      { value: 2, display: "עצמאי" },
      { value: 3, display: "בעל שליטה" },
      { value: 4, display: "אחר" },
      { value: 5, display: "פרט" }
    ]
  },
  active: lookupField("סטאטוס תוכנית", {
    0: "לא פעיל",
    1: "פעיל"
  }),
  active_icon: {
    display: "סטאטוס תוכניות - איקון",
    fieldType: "status",
    getValue(item) {
      return item.active ? "green" : "gray";
    }
  },
  risk_zmani: lookupField("ריסק זמני", {
    0: "לא",
    1: "כן"
  }),
  main_product: { display: "קטגורית מוצר" },
  tikun_190: lookupField("תיקון 190", {
    1: "כן",
    2: "לא"
  }),
  sug_polisa: {
    display: "סוג פוליסה",
    displayValues: [
      { value: 1, display: "קלאסי" },
      { value: 2, display: "עדיף" },
      { value: 3, display: "פוליסות משנת 2004 ואילך" },
      { value: 4, display: 'ריסק ואו אכ"ע' }
    ]
  },
  kod_nispach_kisuy: { display: "מספר נספח כיסוי" },
  meyupe_koach_title: { display: "מיופה כוח" },
  kayam_meyupe_koach: lookupField("קיים מיופה כוח", {
    1: "כן",
    2: "לא"
  }),
  shem_meyupe_koach: { display: "שם מיופה כוח" },
  taarich_minuy_meyupe_koach: dateField("תאריך מינוי מיופה כוח"),
  sug_zihuy_meyupe_koach: lookupField("סוג מיופה כוח", {
    1: "פרטי",
    2: "תאגיד"
  }),
  mispar_zihuy_meyupe_koach: { display: "מספר זיהוי מיופה כוח" },
  index: { display: "מס מעקב" },
  maslul_index: { display: "אינדקס מסלול השקעה" },
  taarich_nechonut: dateField("תאריך נכונות מידע"),
  gil_prisha: { display: "גיל פרישה", fieldType: "age" },
  month_left_to_prisha: { display: "מספר חודשים לפרישה" },
  sachar_polisa: moneyField("שכר בתוכנית"),
  hafkada_chodshei: moneyField("הפקדה חודשית"),
  hotzahot_bituach_oka_or_risk: moneyField("הוצאות ביטוח מעבר לתג+פצ'"),
  hotzahot_bituach: moneyField("הוצאות ביטוח"),
  kod_sug_hafkada: lookupField("סוג הפקדה חודשית", {
    honi: "הוני",
    kitzba: "קצבה",
    "choice till 2000": "בחירה עד שנת 2000",
    "hafkada without saving": "הפקדה ללא חסכון"
  }),
  xachuz_tagmulim_oved: percentField("תגמולי עובד"),
  achuz_tagmulim_maasik: percentField("תגמולי מעביד"),
  achuz_pitzuim: percentField("פיצויים מעביד"),
  sug_hafrashot: { display: "סוג הפרשות" },
  polisa_kidud_achid: { display: "קידוד אחיד" },
  avtacht_tsua: { display: "הבטחת תשואה" },
  taarich_cium_avtacht_tesoa: dateField("תאריך סיום הבטחה"),
  avtacht_tesoa: { display: "הבטחת תשואה + תאריך סיום הבטחה" },
  mekadem_havtachst_tochelet: { display: "מקדם מגלם הבטחת תוחלת חיים?" },
  mekadem_havtachst_tocheletprisha: {
    display: "הבטחת מקדם לכל תוחלת החיים מותנית בבחירת מסלול פרישה"
  },
  mekadem_havtachat_tsua: { display: "המקדם מגלם הבטחת תשואה ?" },
  tochelet_mashpia_kitzba: {
    display: "שינויים בתוחלת חיים משנים את הקצבה לאחר קבלת הקצבה"
  },
  tsua_mashpia_kitzba: {
    display: "שינויים בתשואה משפיעים על מקדם לאחר קבלת קצבה"
  },
  kod_mezahe_yatzran: { display: "ח.פ חברה מנהלת" },
  yatzran_logo: {
    display: "לוגו חברה מנהלת",
    getValue(item) {
      return item.kod_mezahe_yatzran;
    },
    fieldType: "logo"
  },
  shem_yatzran: { display: "חברה מנהלת" },
  service_quality: { display: "איכות השירות" },
  tifhul_quality: { display: "איכות תפעול" },
  tviyot_quality: { display: "איכות ניהול תביעות" },
  tagmulim_before2000: moneyField("תג' עד 00"),
  pitzuim_before2000: moneyField("פצ' עד 00"),
  tagmulim_honi_between2000and2008: moneyField("תג' הון 00-08"),
  pitzuim_honi_between2000and2008: moneyField("פצ' הון 00-08"),
  tagmulim_kitzba_between2000and2008: moneyField("תג' קצבה 00-08"),
  pitzuim_kitzba_between2000and2008: moneyField("פצ' קצבה 00-08"),
  tagmulim_kitzba_after2008: moneyField("תג' קצבה מ-08"),
  pitzuim_kitzba_after2008: moneyField("פצ' קצבה מ-08"),
  honi_4_or_9: moneyField('הון (עבור קה"ש)'),
  total_saving: moneyField('סה"כ חסכון'),
  saving_schum_bituach: moneyField('סה"כ חסכון + ביטוח חיים'),
  pitzuim_honi: moneyField('סה"כ חסכון הוני (פיצויים בלבד)'),
  tagmulim_honi: moneyField('סה"כ חסכון הוני (תגמולים בלבד)'),
  honi_all: moneyField('סה"כ חסכון הוני'),
  pitzuim_kitzba: moneyField('סה"כ חסכון קצבה (פיצויים בלבד)'),
  tagmulim_kitzba: moneyField('סה"כ חסכון קצבה (תגמולים בלבד)'),
  kitzba_all: moneyField('סה"כ חסכון קצבה'),
  pitzuim_all: moneyField('סה"כ חסכון פיצויים'),
  tagmulim_all: moneyField('סה"כ חסכון תגמולים'),

  nazil: lookupField("נזילות", {
    0: "לא נזיל",
    1: "נזיל"
  }),

  total_nazil: moneyField('סה"כ כסף נזיל', {
    getValue(item) {
      return item.nazil == 1 ? item.nazil_amount || item.honi_all || 0 : 0;
    }
  }),

  total_not_nazil: moneyField('סה"כ כסף לא נזיל', {
    getValue(item) {
      return item.nazil == 1 ? item.honi_all - (item.nazil_amount || item.honi_all || 0) : item.honi_all;
    }
  }),

  nazil_in_months: { display: "נזיל עוד חודשים" },

  mekadem_hamara: { display: "מקדם קצבה" },
  fv_kitzba_chodshei_im: moneyField("קצבה חודשית (כולל הפקדות)"),
  fv_kitzba_chodshei_lelo: moneyField("קצבה חודשית (לא כולל הפקדות)"),
  fv_honi_pitzuim_amount_im: moneyField(
    "סך צבירה פיצויים הוניים (כולל הפקדות)"
  ),
  fv_honi_pitzuim_amount_lelo: moneyField(
    "סך צבירה פיצויים הוניים (לא כולל הפקדות)"
  ),
  fv_honi_tagmulim_amount_im: moneyField(
    "סך צבירה תגמולים הוניים (כולל הפקדות)"
  ),
  fv_honi_tagmulim_amount_lelo: moneyField(
    "סך צבירה תגמולים הוניים (לא כולל הפקדות)"
  ),
  fv_honi_amount_im: moneyField("סך צבירה הונית (כולל הפקדות)"),
  fv_honi_amount_lelo: moneyField("סך צבירה הונית (לא כולל הפקדות)"),
  fv_kitzba_pitzuim_amount_im: moneyField(
    "סך צבירה פיצויים למטרת קצבה (כולל הפקדות)"
  ),
  fv_kitzba_pitzuim_amount_lelo: moneyField(
    "סך צבירה פיצויים למטרת קצבה (לא כולל הפקדות)"
  ),
  fv_kitzba_tagmulim_amount_im: moneyField(
    "סך צבירה תגמולים למטרת קצבה (כולל הפקדות)"
  ),
  fv_kitzba_tagmulim_amount_lelo: moneyField(
    "סך צבירה תגמולים למטרת קצבה (לא כולל הפקדות)"
  ),
  fv_kitzba_amount_im: moneyField("סך צבירה למטרת קצבה (כולל הפקדות)"),
  fv_kitzba_amount_lelo: moneyField("סך צבירה למטרת קצבה (לא כולל הפקדות)"),
  fv_pitzuim_amount_im: moneyField("סך צבירה פיצויים (כולל הפקדות)"),
  fv_pitzuim_amount_lelo: moneyField("סך צבירה פיצויים (לא כולל הפקדות)"),
  fv_tagmulim_amount_im: moneyField("סך צבירה תגמולים (כולל הפקדות)"),
  fv_tagmulim_amount_lelo: moneyField("סך צבירה תגמולים (לא כולל הפקדות)"),
  amount_to_prisha: moneyField('סה"כ כספים לגיל פרישה'),
  amount_to_prisha_lelo: moneyField('סה"כ כספים לגיל פרישה (לא כולל הפקדות)'),
  dmei_nihul_shiur_hafkada: percentField("דמי ניהול מפרמיה", {
    resolve: weightedAverage("dmei_nihul_shiur_hafkada", "hafkada_chodshei")
  }),
  dmei_nihul_shiur_tzvira: percentField("דמי ניהול מצבירה", {
    resolve: weightedAverage("dmei_nihul_shiur_tzvira", "total_saving")
  }),
  dmei_nihul_shiur_mirevach: percentField("דמי ניהול מרווחים"),
  dmei_nihul_shiur_tzvira_bafoal: percentField("דמי ניהול צבירה בפעול"),
  schum_max_dnhl_hafkada: moneyField("מקסימום דמי ניהול"),
  dmei_nihul_acherim: percentField("דמי ניהול אחרים"),
  amlat_nihul_hitzoni: percentField("עמלת ניהול חיצוני"),
  shiur_alot_shnatit: percentField("שיעור עלות שנתי"),
  sach_dmei_nihul_acherim: moneyField("הוצאות  חודשיות אחרות"),
  dmei_nihul_chodshei: moneyField("דמי ניהול חודשיים"),
  dmei_nihul_shnati: moneyField("דמי ניהול שנתיים"),
  dmei_nihul_shnati_average: percentField("אחוז דמי ניהול שנתיים", {
    resolve(items, aggregation, context) {
      if (aggregation == "average") {
        let total_saving = context.aggregateField(items, "total_saving", "sum");
        let dmei_nihul_shnati = context.aggregateField(
          items,
          "dmei_nihul_shnati",
          "sum"
        );
        return (100 * dmei_nihul_shnati) / total_saving;
      } else {
        return context.aggregateField(
          items,
          (item) => (100 * item.dmei_nihul_shnati) / item.total_saving,
          aggregation
        );
      }
    }
  }),
  dmei_nihul_gil_prisha: moneyField("דמי ניהול לגיל פרישה"),
  dmei_nihul_hafkada: moneyField("דמי ניהול הפקדה"),
  sachar_kovea_le_nechut_ve_sheerim: moneyField("ההכנסה הקובעת לנכות ושאירים"),
  gil_prisha_lepensiyat_zikna: { display: "תום תקופת ביטוח", fieldType: "age" },
  shaerim_all: moneyField('סה"כ שארים'),
  sheerim_sug_vitor: lookupField("ביטוח שארים", {
    0: "ביטוח אלמן/אלמנה ויתום",
    1: "ללא ביטוח שארים בכלל",
    3: "ביטוח לאלמן/אלמנה בלבד",
    4: "ביטוח ליתום בלבד"
  }),
  sheerim_vitor_gil_60_and_above: lookupField("ויתור פנסיית שארים מגיל 60", {
    1: "כן",
    2: "לא",
    3: "לא רלוונטי"
  }),
  pansia_nechut: moneyField("פנסיית נכות"),
  alman: moneyField("שארים אלמנה"),
  yatom: moneyField("שאירי יתום"),
  alman_alut: moneyField("שארים אלמנה עלות"),
  yatom_alut: moneyField("שארים יתום עלות"),
  kisuim_death: moneyField("ביטוח מקרה מוות"),
  death_alut: moneyField("ב.מ.מ עלות"),
  kisuim_nechut: moneyField("אובדן כושר עבודה (א.כ.ע) / פנסיית נכות"),
  nechut_alut: moneyField("עלות א.כ.ע / פנסיית נכות"),
  nechut_mitpatahat: lookupField("פנסיית נכות מתפתחת", {
    1: "כן",
    2: "לא"
  }),
  kolel_prenzisa: lookupField("פרנציזה (נכות כפולה)", {
    1: "כן",
    2: "לא"
  }),
  nechut_vitor_gil_60_and_above: lookupField("ויתור פנסיית נכות מגיל 60", {
    1: "כן",
    2: "לא",
    3: "לא רלוונטי"
  }),
  kod_maslul_bituach: { display: "קוד מסלול ביטוח" },
  maslul_bituach: { display: "מסלול ביטוח" },

  mispar_kupa: { display: "מס' מס הכנסה" },
  mispar_maslul: { display: "מס' מסלול" },
  shm_maslul: { display: "מסלול ההשקעה" },
  maslul_breyrat_mechdal: lookupField("ברירת מחדל מסלול", {
    0: "לא",
    1: "כן"
  }),
  hitmahut_mishnit: { display: "קטגורית השקעה" },
  divuach_achron: dateField("תאריך עדכון נתוני מסלול"),
  tsua_mi_thilat_shana: weightedPercentField(
    "תשואה מתחילת שנה",
    "tsua_mi_thilat_shana",
    "total_saving"
  ),
  tsua_mitztaberet_6_hodashim: percentField(
    "תשואה 6 חודשים",
    "tsua_mitztaberet_6_hodashim",
    "total_saving"
  ),
  tsua_mitztaberet_12_hodashim: percentField(
    "תשואה 12 חודשים",
    "tsua_mitztaberet_12_hodashim",
    "total_saving"
  ),
  tsua_mitztaberet_24_hodashim: percentField(
    "תשואה 24 חודשים",
    "tsua_mitztaberet_24_hodashim",
    "total_saving"
  ),
  tsua_mitztaberet_36_hodashim: percentField(
    "תשואה 36 חודשים",
    "tsua_mitztaberet_36_hodashim",
    "total_saving"
  ),
  tsua_mitztaberet_60_hodashim: percentField(
    "תשואה 60 חודשים",
    "tsua_mitztaberet_60_hodashim",
    "total_saving"
  ),
  tsua_memuzaat_2_shanim: percentField(
    "תשואה ממוצע 2 שנים",
    "tsua_memuzaat_2_shanim",
    "total_saving"
  ),
  tsua_memuzaat_3_shanim: percentField(
    "תשואה ממוצע 3 שנים",
    "tsua_memuzaat_3_shanim",
    "total_saving"
  ),
  tsua_memuzaat_5_shanim: percentField(
    "תשואה ממוצע 5 שנים",
    "tsua_memuzaat_5_shanim",
    "total_saving"
  ),
  stiat_teken_36_hodashim: percentField(
    "סטיית תקן 36 חודשים",
    "stiat_teken_36_hodashim",
    "total_saving"
  ),
  stiat_teken_60_hodashim: percentField(
    "סטיית תקן 60 חודשים",
    "stiat_teken_60_hodashim",
    "total_saving"
  ),
  mazan_actuari: { display: "גרעון/עודף אקטוארי" },
  sharp_anaf: { display: "שארפ ענף" },
  sharp_tsua_hetzyonit_anaf: { display: "שארפ חציוני ענף" },
  sharp_ribit_hasrat_sikun: { display: "שארפ חסר סיכון" },
  ramat_sikun_menayot: percentField("אחוז מניות", {
    resolve: weightedAverage("ramat_sikun_menayot", "total_saving")
  }),
  total_menayot: moneyField('סה"כ כסף במניות', {
    getValue(item) {
      return (+item.total_saving * +item.ramat_sikun_menayot) / 100;
    }
  }),
  ramat_sikun_foreign: percentField("אחוז חשיפה לחו\"ל" , {
    resolve: weightedAverage("ramat_sikun_foreign", "total_saving")
  }),
  ramat_sikun_foreign_currency:percentField( "אחוז חשיפה למט\"ח" , {
    resolve: weightedAverage("ramat_sikun_foreign_currency", "total_saving")
  }),

  ramat_sikun: { display: "דרגת סיכון במניות" },
  ribit_neto: percentField("ריבית נטו"),
  ribit_broto: percentField("ריבית ברוטו"),
  ribit_broto_prisha: percentField("ריבית ברוטו מגיל פרישה"),
  sug_keren_pensia: lookupField("סוג קרן פנסיה", {
    1: "יסוד",
    2: "מקיפה",
    3: "כללית"
  }),
  pensia_vatika_o_hadasha: lookupField("קרן פנסיה (חדשה/ותיקה)", {
    1: "ותיקה",
    2: "חדשה"
  }),
  pensia_shiur_kisui_alman_be_prisha: percentField(
    "שיעור כיסוי לאלמן לאחר פרישה"
  ),
  pensia_tkufat_havtacha_month: { display: "תקופת הבטחת תשלומים פנסיה" },
  mispar_months_bakeren_till_now: { display: "מספר חודשים נוכחי בקרן" },
  mispar_months_bakeren_till_prisha: { display: "מספר חודשים בקרן עד פרישה" },
  ahuz_pensiya_tzvura: percentField("אחוז פנסיה צבורה"),
  pensia_vatika_gironit_o_meuzenet: lookupField("סוג פוליסה ותיקה", {
    "": "",
    gironit: "גרעונית",
    meuzenet: "מאוזנת"
  }),
  amit_mevutach_tkufat_beynaim: percentField("תקופת ביניים"),
  ahuz_vetek_now: percentField("אחוז ותק נוכחי"),
  ahuz_vetek_prisha: percentField("אחוז ותק עד פרישה"),
  sachar_koveaa_lelo: moneyField("שכר קובע ללא הפקדות"),
  sachar_koveaa_im: moneyField("שכר קובע כולל הפקדות"),
  months_pensia_yesod_lelo: { display: "חודשי פנסית יסוד לא כולל הפקדה" },
  months_pensia_yesod_im: { display: "חודשי פנסית יסוד כולל הפקדה" },
  months_pensia_mekifa_lelo: { display: "חודשי פנסיה מקיפה לא כולל הפקדה" },
  months_pensia_mekifa_im: { display: "חודשי פנסיה מקיפה כולל הפקדה" },

  maanak_odfot_lelo: moneyField("מענק שנים עודפות בגיל פרישה לא כולל הפקדות"),
  maanak_odfot_im: moneyField("מענק שנים עודפות בגיל פרישה כולל הפקדות"),

  tags: { display: "קטגוריות" }
};

export default mutzarimResolvers;
