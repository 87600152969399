<template>
  <v-row dense>
    <v-col>
      <v-row dense>
        <v-col>
          <field-selector
            v-model="card.field"
            :context="card.context"
          ></field-selector>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-select
            label="מקרא"
            v-model="card.legend"
            :items="legendPositions"
            dense
            outlined
            hide-details
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FieldSelector from "../FieldSelector.vue";
export default {
  props: {
    card: { type: Object, required: true }
  },
  components: {
    FieldSelector
  },
  data() {
    return {
      legendPositions: [
        { value: false, text: "ללא" },
        { value: "top", text: "למעלה" },
        { value: "bottom", text: "למטה" },
        { value: "left", text: "שמאל" },
        { value: "right", text: "ימין" }
      ]
    };
  },
  methods: {
    startEditComplexString(item, field) {
      this.$emit("edit-complex-string", item, field);
    }
  }
};
</script>

<style></style>
