const riskRibitMapCardResolver = {
  supported(card) {
    return card.type == "RISK_RIBIT_MAP";
  },
  resolveCard(context, card) {
    let filteredContext = context.applyFilterByConditions(
      card.context,
      card.conditions
    );

    console.log("filteredContext", filteredContext);
    let items = filteredContext.contexts[card.context];

    return {
      items,
      ...card,
      title: filteredContext.interpolateElements(card.title)
    };
  }
};

export default riskRibitMapCardResolver;
