import "./plugins/filters";

import * as Sentry from "@sentry/vue";

import Account from "./plugins/Account";
import App from "./App.vue";
import Backend from "./plugins/Backend";
import Recaptcha from "./plugins/Recaptcha";
import Store from "./plugins/Store";
import StoreActions from "./plugins/StoreActions";
import Tracing from "./plugins/Tracing";
import TreeView from "vue-json-tree-view";
import Vue from "vue";
import VueResource from "vue-resource";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.use(VueResource);
Vue.use(Backend);
Vue.use(Account);
Vue.use(Recaptcha);
Vue.use(Store);
Vue.use(StoreActions);
Vue.use(TreeView);

Vue.config.productionTip = false;

Vue.prototype.$bus = new Vue();

if (window.location.hostname.indexOf("addedvalue.co.il") !== -1) {
  // eslint-disable-next-line no-console
  console.log("initialize Sentry");

  Sentry.init({
    Vue,
    // see how we get this into settings and not inside code.
    dsn: "https://197253c9a32d4ae385f80746da1bf5f4@o290523.ingest.sentry.io/5648286",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    logErrors: true
  });

  Vue.use(Tracing, { Sentry });
} else {
  Vue.use(Tracing, {});
}

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
