class ReportApi {
  constructor(vm, context, state) {
    this.$backend = vm.$backend;
    this.state = state;
    this.context = context;
    if (this.context.scope == "mislaka") {
      this.root = `/mislaka/request/${this.context.reportId}`;
    } else {
      this.root = `/client/${this.context.clientId}/report/${this.context.reportId}`;
    }
  }

  load() {
    return Promise.all([
      this.loadInfo(),
      this.loadScope(),
      this.loadReport("scope")
    ]).then(() => this.state);
  }

  async loadInfo() {
    this.state.info = await this.$backend.getJson(this.root + "/info");
    return this.state;
  }

  async loadInfoValues() {
    this.state.infoValues = await this.$backend.getJson(
      this.root + "/info-values"
    );
    return this.state;
  }

  async updateInfo(newInfo) {
    this.state.info = await this.$backend.postJson(
      this.root + "/info",
      newInfo
    );
    return this.state;
  }

  async loadScope() {
    this.state.scope = await this.$backend.getJson(this.root + "/scope");
    return this.state;
  }

  loadReport(mode) {
    let promise;
    if (mode == "reset") {
      promise = this.$backend.postJson(this.root, {}).then((report) => {
        this.state.report = report;
        return this.state;
      });
    } else if (mode == "invalidate") {
      promise = this.$backend
        .getJson(this.root + "/invalidate")
        .then((report) => {
          this.state.report = report;
          return this.state;
        });
    } else {
      promise = this.$backend.getJson(this.root).then((report) => {
        this.state.report = report;
        return this.state;
      });
    }
    if (mode == "scope") {
      promise = promise.then(() => {
        return this.$backend
          .getJson(this.root + "/report-scope")
          .then((reportScope) => {
            this.state.reportScope = reportScope;
            return this.state;
          });
      });
    }
    return promise;
  }

  invalidate() {
    return this.$backend.getJson(this.root + "/invalidate");
  }

  explain() {
    return this.$backend.getJson(this.root + "/explain");
  }

  analyze(options) {
    return this.$backend.getJson(this.root + "/analyze", {
      params: options
    });
  }

  content() {
    return this.$backend.getJson(this.root + "/content");
  }

  loadPolisot(state = "before") {
    return this.$backend.getJson(this.root + "/" + state);
  }

  sanitizeReport(report) {
    //remove fixes suggestions from polisot
    let json = JSON.stringify(report, (key, value) => {
      if (key == "fixes") return undefined;
      if (key == "changesSummary") return undefined;
      return value;
    });
    return JSON.parse(json);
  }

  async updateReport(newReport, newForms) {
    this.state.report = await this.$backend.postJson(this.root, {
      report: this.sanitizeReport(newReport),
      forms: newForms
    });
    return this.state;
  }

  async archiveReport(archive) {
    this.state.report = await this.$backend.postJson(this.root + "/archive", {
      archive
    });
    return this.state;
  }

  deleteReport() {
    return this.$backend.delete(this.root).then((/* response */) => {
      return this.state;
    });
  }

  duplicateReport(newReport, options = {}) {
    return this.$backend.postJson(this.root + "/duplicate", newReport, {
      params: options
    });
  }

  async suggestReportCode() {
    return await this.$backend.postJson(this.root + "/suggest-report-code", {});
  }

  async recalculateReport(newReport) {
    this.state.report = await this.$backend.postJson(
      this.root + "/recalculate",
      newReport
    );
    return this.state;
  }

  async recalculateReportWithFixes(newReport, { update } = { update: true }) {
    let reportWithFixes = await this.$backend.postJson(
      this.root + "/recalculate?fixes=true",
      //this.root + "/recalculate",
      newReport
    );
    if (update) {
      this.state.report = this.sanitizeReport(reportWithFixes);
    }
    return reportWithFixes;
  }

  analyzeReport(report, options) {
    return this.$backend.postJson(this.root + "/analyze", report, {
      params: options
    });
  }

  async optimizeReport(report, field, riskLevel) {
    let { optimizedReport, optimizationForMutzar } = await this.$backend.postJson(this.root + "/optimize", { report, field, riskLevel });
    return { optimizedReport, optimizationForMutzar };
  }

  async loadForms() {
    this.state.forms = await this.$backend.getJson(this.root + "/forms");
    return this.state;
  }

  async updateForms(forms) {
    this.state.forms = await this.$backend.postJson(
      this.root + "/forms",
      forms
    );
    return this.state;
  }

  async generateForms(forms, onUpdate) {
    let jobId = await this.$backend.postJson("/job", {
      job: "generate-forms",
      inputs: { 
        clientId: this.context.clientId,
        reportId: this.context.reportId,
        ui: `${window.location.protocol}//${window.location.host}`,
        forms 
      }
    });
    let status = await this.$backend.getJson("/job/" + jobId + "/status");
    onUpdate(status);
    while (!status.done) {
      status = await this.$backend.getJson("/job/" + jobId + "/status");
      onUpdate(status);
    }
    return this.$backend.getJson("/job/" + jobId);
  }

  isClientPublished() {
    return this.$backend.getJson(this.root + "/is-published", {});
  }

  async publish(people) {
    let packageId =
      (this.state.report &&
        this.state.report.publishInfo &&
        this.state.report.publishInfo.id) ||
      undefined;
    this.state.report = await this.$backend.postJson(this.root + "/publish", {
      people,
      packageId
    });
    return this.state;
  }

  async sendSignPackage(person, method, title) {
    return await this.$backend.postJson(this.root + "/send-sign-package", {
      person,
      method,
      title
    });
  }

  async getFile(file) {
    let response = await this.$backend.get(this.root + "/file/" + file);
    return await response.text();
  }

  hasChanges() {
    return this.$backend.getJson(this.root + "/changes");
  }

  recalculateReportWithChanges(report) {
    return this.$backend.postJson(this.root + "/changes?fixes=true", { report });
  }

  compareReport(report, referenceId) {
    return this.$backend.postJson(this.root + "/changes", { report, referenceId });
  }

  getInfoChanges() {
    return this.$backend.getJson(this.root + "/info-changes");
  }

  getReportMissingFields(report, referenceReport) {
    return this.$backend.postJson(this.root + "/find-missing-fields", { report, referenceReport});
  }

  getMislakaProcess() {
    return this.$backend.getJson(this.root + "/mislaka-process");
  }
}

class ClientApi {
  constructor(vm, context, state) {
    this.vm = vm;
    this.$backend = vm.$backend;
    this.state = state;
    this.context = context;
    this.root = `/client/${this.context.clientId}`;
    this.vm.$set(this.state, "reportsById", {});
  }

  async load() {
    let response = await this.$backend.getJson(this.root);
    if (response.newReports && response.newReports.length > 0) {
      response = await this.refreshReport(response.newReports);
    }
    this.state.info = response.info;
    this.state.lastReport = response.lastReport;
    this.state.reports = response.reports;
    this.vm.$set(this.state, "reportsById", {});
    return this.state;
  }

  refreshReport(reports) {
    return this.$backend.postJson(this.root + "/refresh", {
      reports
    });
  }

  loadInfo() {
    return this.$backend.getJson(this.root + "/info");
  }

  loadAccount() {
    return this.$backend.getJson(this.root + "/account");
  }

  loadLoginHistory() {
    return this.$backend.getJson(this.root + "/login-history");
  }

  unlockClient() {
    return this.$backend.postJson(this.root + "/login-history", { locked: false });
  }

  report(id) {
    if (!(id in this.state.reportsById)) {
      this.state.reportsById[id] = {
        id: id,
        info: undefined,
        infoValues: undefined,
        scope: undefined,
        report: undefined,
        reportScope: undefined,
        forms: undefined
      };
    }
    return new ReportApi(
      this.vm,
      { scope: "client", clientId: this.context.clientId, reportId: id },
      this.state.reportsById[id]
    );
  }

  deleteReport(id) {
    return this.report(id)
      .deleteReport()
      .then(() => {
        return this.load();
      });
  }

  duplicateReport(id, newReport, options) {
    return this.report(id)
      .duplicateReport(newReport, options)
      .then((report) => {
        return this.load().then(() => {
          return report;
        });
      });
  }

  getPublishedReport() {
    return this.$backend.getJson(this.root + "/publishedReport");
  }

  setPublishedReport(id) {
    return this.$backend
      .postJson(this.root + "/publishedReport", { reportId: id })
      .then((response) => {
        this.state.info = response.info;
        this.state.lastReport = response.lastReport;
        this.state.reports = response.reports;
        return this.state;
      });
  }

  updateAccount(account) {
    return this.$backend.postJson(this.root + "/account", account);
  }

  getReportCodes() {
    return this.$backend.getJson(this.root + "/report-codes");
  }

  loadInvites() {
    return this.$backend.getJson(this.root + "/invite");
  }

  createInvite() {
    return this.$backend.postJson(this.root + "/invite", {});
  }

  resendInvite(inviteId) {
    return this.$backend.postJson(
      this.root + "/invite/" + inviteId + "/resend",
      {}
    );
  }

  deleteInvite(inviteId) {
    return this.$backend.deleteJson(this.root + "/invite/" + inviteId, {});
  }

  startGetDataRequest() {
    return this.$backend.postJson(this.root + "/request-data", {});
  }

  loadLastEmails() {
    return this.$backend.getJson(this.root + "/audit/emails");
  }

  loadMessageThreads() {
    return this.$backend.getJson(this.root + "/messages");
  }

  postMessage(thread, message, markRead) {
    return this.$backend.postJson(this.root + "/messages", {
      threadId: thread.id,
      title: thread.title,
      message,
      markRead
    });
  }

  markRead(thread, message) {
    return this.$backend.postJson(this.root + "/messages/read", {
      threadId: thread.id,
      messageId: message.id
    });
  }

  uploadYiupiKoach(hevrot, idFile, b1File, taarich_chtima_lakoach, taarich_chtima_baal_rishaion) {
    let formData = new FormData();
    formData.append("hevrot", hevrot);
    if (taarich_chtima_lakoach) {
      formData.append("taarich_chtima_lakoach", taarich_chtima_lakoach);
    }
    if (taarich_chtima_baal_rishaion) {
      formData.append("taarich_chtima_baal_rishaion", taarich_chtima_baal_rishaion);
    }
    formData.append("id_file", idFile);
    formData.append("b1_fill_file", b1File);
    return this.$backend.postJson(this.root + "/yiupi-koach", formData);
  }
}

export default {
  install(Vue /* options */) {
    const actions = {};
    Vue.prototype.$actions = actions;

    const actionVm = new Vue({});

    actions.mislaka = {
      stats() {
        return actionVm.$backend.getJson("/mislaka/stats");
      },
      upload(input) {
        let fileToUpload = input.files[0];
        let formData = new FormData();
        formData.append("file", fileToUpload);
        return actionVm.$backend.postJson("/mislaka/request", formData);
      },
      getDownloadedFile(file) {
        return actionVm.$backend.getJson("/mislaka/downloaded/" + file);
      },
      file(id) {
        if (!(id in actionVm.$store.filesById)) {
          actionVm.$store.filesById[id] = {
            id: id,
            info: undefined,
            scope: undefined,
            report: undefined,
            reportScope: undefined
          };
        }
        return new ReportApi(
          actionVm,
          { scope: "mislaka", reportId: id },
          actionVm.$store.filesById[id]
        );
      },
      import(id) {
        return actionVm.$backend.postJson("/mislaka/import/" + id, {});
      },
      requestFileProcess() {
        return actionVm.$backend.postJson("/mislaka/request-file-process", {});
      },
      updateTags(request) {
        return actionVm.$backend.postJson("/mislaka/request/updateTags", request);
      }
    };

    actions.clients = {
      loadClients() {
        return actionVm.$backend.getJson("/client").then((clients) => {
          let ids = {};
          actionVm.$store.clients = clients.map((client) => {
            let obj = {
              id: client.id,
              info: client.info,
              lastReport: client.lastReport,
              account: client.account,
              scope: undefined,
              reports: undefined,
              report: undefined
            };
            ids[client.id] = obj;
            return obj;
          });
          actionVm.$store.clientsById = ids;
          return actionVm.$store.clients;
        });
      },
      loadActiveClients() {
        return actionVm.$backend.getJson("/client/active");
      },
      searchClientsWithProducts(products) {
        return actionVm.$backend.getJson("/client/search", {
          params: products
        });
      },
      create(info) {
        return actionVm.$backend.postJson("/client/", { info });
      },
      client(id) {
        if (!(id in actionVm.$store.clientsById)) {
          actionVm.$store.clientsById[id] = {
            id: id,
            info: undefined,
            lastReport: undefined,
            scope: undefined,
            reports: undefined,
            report: undefined
          };
        }
        return new ClientApi(
          actionVm,
          { scope: "client", clientId: id },
          actionVm.$store.clientsById[id]
        );
      }
    };

    actions.currentReport = {
      async load() {
        return actionVm.$backend.getJson("/account/report/current");
      },
      async info() {
        return actionVm.$backend.getJson("/account/report/current/info");
      },
      async analyze(options) {
        return actionVm.$backend.getJson("/account/report/current/analyze", {
          params: options
        });
      },
      async explain() {
        return actionVm.$backend.getJson("/account/report/current/explain");
      },
      async content() {
        return actionVm.$backend.getJson("/account/report/current/content");
      },
      async forms() {
        return actionVm.$backend.getJson("/account/report/current/forms");
      },
      async updateForms(forms) {
        return actionVm.$backend.postJSon(
          "/account/report/current/forms",
          forms
        );
      }
    };

    actions.messages = {
      load() {
        return actionVm.$backend.getJson("/account/messages");
      },
      loadUnread() {
        return actionVm.$backend.getJson("/account/messages/unread");
      },
      postMessage(thread, message) {
        return actionVm.$backend.postJson("/account/messages", {
          threadId: thread.id,
          title: thread.title,
          message
        });
      },
      markRead(thread, message) {
        return actionVm.$backend.postJson("/account/messages/read", {
          threadId: thread.id,
          messageId: message.id
        });
      }
    };

    actions.getRankingDateRange = function () {
      return actionVm.$backend.getJson(`/mutzar/date-range`);
    };

    actions.updateRanking = function () {
      return actionVm.$backend.postJson(`/mutzar/update`);
    };

    actions.loadMaslulim = function (sugMutzar, month) {
      if (
        actionVm.$store.maslulimDate[sugMutzar] == month &&
        actionVm.$store.maslulim[sugMutzar]
      ) {
        return Promise.resolve(actionVm.$store.maslulim[sugMutzar]);
      }

      return actionVm.$backend
        .getJson(`/mutzar/${sugMutzar}/maslul?month=${month}`)
        .then((newMaslulim) => {
          Vue.set(actionVm.$store.maslulim, sugMutzar, newMaslulim);
          Vue.set(actionVm.$store.maslulimDate, sugMutzar, month);
          return actionVm.$store.maslulim[sugMutzar];
        });
    };

    actions.loadMaslulimRanking = function (sugMutzar, category, month) {
      return actionVm.$backend.getJson(
        `/mutzar/${sugMutzar}/rank/${category}?month=${month}`
      );
    };

    actions.loadRowRanking = function (row, threshold, months) {
      if (threshold) {
        return actionVm.$backend.postJson(
          `/mutzar/${row.sug_mutzar}/rank/byRamatSikun?threshold=${threshold}&months=${months}`,
          row
        );
      } else {
        return actionVm.$backend.postJson(
          "/mutzar/" + row.sug_mutzar + "/rank/",
          row
        );
      }
    };

    actions.loadBituachMaslulim = function (sugKisui) {
      return actionVm.$backend
        .getJson("/bituach/" + sugKisui + "/maslul")
        .then((bituchim) => {
          Vue.set(actionVm.$store.bituachMaslulim, sugKisui, bituchim);
          return actionVm.$store.bituachMaslulim[sugKisui];
        });
    };

    actions.getRequiredFormsForRow = function (row, info, polisot) {
      return actionVm.$backend.postJson("/forms/requiredForms", {
        row,
        info,
        polisot
      });
    };

    actions.getSpecificFormsForRow = function (rules, row, info, polisot) {
      return actionVm.$backend.postJson("/forms/fillForms", {
        rules,
        row,
        info,
        polisot
      });
    };

    actions.getAvailableFieldsForRow = function (row, info, polisot) {
      return actionVm.$backend.postJson("/forms/availableFields", {
        row,
        info,
        polisot
      });
    };

    actions.loadForms = function () {
      return actionVm.$backend.getJson("/forms/").then((formsByCategory) => {
        actionVm.$store.formsByCategory = formsByCategory;
        actionVm.$store.forms = [].concat(
          ...Object.values(actionVm.$store.formsByCategory).map(
            (category) => category.forms
          )
        );
        return actionVm.$store.forms;
      });
    };

    actions.loadFormsRules = function () {
      return actionVm.$backend.getJson("/forms/rules").then((rules) => {
        rules.forEach((rule) => {
          rule.checked = false;
        });
        actionVm.$store.formsRules = rules;
        return actionVm.$store.formsRules;
      });
    };

    actions.saveFormRule = function (rule) {
      return actionVm.$backend
        .postJson("/forms/rules", { rule })
        .then((newRule) => {
          let foundItem = actionVm.$store.formsRules.filter(
            (i) => i.id == newRule.id
          )[0];
          if (foundItem) {
            let index = actionVm.$store.formsRules.indexOf(foundItem);
            actionVm.$store.formsRules.splice(index, 1, newRule);
          } else {
            actionVm.$store.formsRules.push(newRule);
          }
          return actionVm.$store.formsRules;
        });
    };

    actions.deleteFormRule = function (rule) {
      return actionVm.$backend
        .deleteJson("/forms/rules/" + rule.id)
        .then((deletedRule) => {
          let foundItem = actionVm.$store.formsRules.filter(
            (i) => i.id == deletedRule.id
          )[0];
          if (foundItem) {
            let index = actionVm.$store.formsRules.indexOf(foundItem);
            actionVm.$store.formsRules.splice(index, 1);
          }
          return actionVm.$store.formsRules;
        });
    };

    actions.downloadEmptyForm = function (formId, clientId, reportId) {
      //we found out that changing the form on the server doesn't invalide the requests cache.
      //ading some random tags to skip the cache.
      let tag = Math.random();
      return actionVm.$backend
        .get("/forms/" + formId, {
          params: { clientId, reportId, tag }
        })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error("fail to download form");
        });
    };

    actions.completeForm = function (
      formId,
      fields,
      signatures,
      clientId,
      reportId,
      flatten
    ) {
      return actionVm.$backend
        .post(
          "/forms/" + formId,
          { fields, signatures, flatten: !!flatten },
          {
            params: { clientId, reportId }
          }
        )
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error("fail to download form");
        });
    };

    actions.completeSeveralForms = async function (forms, clientId, reportId, flatten, onUpdate) {
      let jobId = await actionVm.$backend.postJson("/job", {
        job: "zip-forms",
        inputs: { forms, clientId, reportId, flatten }
      });
      let status = await actionVm.$backend.getJson("/job/" + jobId + "/status");
      onUpdate(status);
      while (!status.done) {
        status = await actionVm.$backend.getJson("/job/" + jobId + "/status");
        onUpdate(status);
      }
      return actionVm.$backend.get("/job/" + jobId).then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error("fail to download forms");
      });
    };

    function sanitizeInfo(info) {
      let fixedInfo = { ...info };
      delete fixedInfo.title;
      delete fixedInfo.description;

      return fixedInfo;
    }

    actions.recalculateRowWithFixes = function (row, info, type) {
      switch (type) {
        case "polisa":
          return actionVm.$backend.postJson("/row", {
            row,
            info: sanitizeInfo(info)
          });
        case "bituach":
          return actionVm.$backend.postJson("/bituach_row", {
            row,
            info: sanitizeInfo(info)
          });
        case "halvaa":
          return Promise.resolve({ row });
        default:
          throw new Error("unknown row type " + type);
      }
    };

    actions.recalculateRow = function (row, info, type) {
      return actions
        .recalculateRowWithFixes(row, info, type)
        .then((result) => result.row);
    };

    actions.simulateRow = function (row, info, type, monthDelta = 0) {
      switch (type) {
        case "polisa":
          return actionVm.$backend.postJson("/row/simulate", {
            row,
            info: sanitizeInfo(info),
            delta: monthDelta
          });
        case "bituach":
          return actionVm.$backend.postJson("/bituach_row/simulate", {
            row,
            info: sanitizeInfo(info),
            delta: monthDelta
          });
        case "halvaa":
          throw new Error("Can't simulate halvaa");
        default:
          throw new Error("unknown row type " + type);
      }
    };

    actions.loadSavedItems = function () {
      return actionVm.$backend.getJson("/templates/").then((savedItems) => {
        actionVm.$store.savedItems = savedItems;
        return actionVm.$store.savedItems;
      });
    };

    actions.saveItem = function (name, item, type) {
      return actionVm.$backend
        .postJson("/templates/", { name, item, type })
        .then((savedItem) => {
          let foundItem = actionVm.$store.savedItems.filter(
            (i) => i.name == name
          )[0];
          if (foundItem) {
            foundItem.item = savedItem.item;
            foundItem.type = savedItem.type;
          } else {
            foundItem = savedItem;
            actionVm.$store.savedItems.push(savedItem);
          }
          return foundItem;
        });
    };

    actions.removeSavedItem = function (name) {
      let foundItem = actionVm.$store.savedItems.filter(
        (savedItem) => savedItem.name == name
      )[0];
      if (foundItem) {
        let index = actionVm.$store.savedItems.indexOf(foundItem);
        actionVm.$store.savedItems.splice(index, 1);
        return actionVm.$backend.delete(
          "/templates/" + encodeURIComponent(name)
        );
      } else {
        return Promise.resolve(null);
      }
    };

    actions.loadFilters = function () {
      return actionVm.$backend.getJson("/filters/store").then((filters) => {
        actionVm.$store.filters = filters;
        return actionVm.$store.filters;
      });
    };

    actions.loadFiltersGroups = function () {
      return actionVm.$backend.getJson("/filters/groups");
    };

    actions.saveFilter = function (filter) {
      return actionVm.$backend
        .postJson("/filters/store", filter)
        .then((newFilter) => {
          actionVm.$set(actionVm.$store.filters, newFilter.name, newFilter);
          return newFilter;
        });
    };

    actions.removeFilter = function (filter) {
      return actionVm.$backend
        .delete("/filters/store/" + filter.name)
        .then((/* response */) => {
          actionVm.$delete(actionVm.$store.filters, filter.name);
          return filter;
        });
    };

    actions.testFilter = function (filter) {
      return actionVm.$backend.postJson("/filters/match/", filter);
    };

    actions.loadHanmakaLines = function () {
      return actionVm.$backend
        .getJson("/hanmaka/store")
        .then((hanmakaLines) => {
          actionVm.$store.hanmakaLines = hanmakaLines;
          return actionVm.$store.hanmakaLines;
        });
    };

    function findHanmkaLine(conditions) {
      let key = JSON.stringify(conditions);
      for (let line of actionVm.$store.hanmakaLines) {
        if (JSON.stringify(line.conditions) == key) {
          return line;
        }
      }
      return undefined;
    }

    function isEmptyHanmkaLine(lines) {
      return (
        lines.hevraLines.pros.length == 0 &&
        lines.hevraLines.cons.length == 0 &&
        lines.mutzarLines.pros.length == 0 &&
        lines.mutzarLines.cons.length == 0
      );
    }

    function updateLine(lines) {
      let foundLines = findHanmkaLine(lines.conditions);
      if (foundLines) {
        if (isEmptyHanmkaLine(lines)) {
          let foundIndex = actionVm.$store.hanmakaLines.indexOf(foundLines);
          actionVm.$store.hanmakaLines.splice(foundIndex, 1);
        } else {
          foundLines.mutzarLines = lines.mutzarLines;
          foundLines.hevraLines = lines.hevraLines;
        }
      } else {
        actionVm.$store.hanmakaLines.push(lines);
      }
    }

    actions.saveHanmakaLines = function (multiLines) {
      return actionVm.$backend
        .postJson("/hanmaka/store/multiple", multiLines)
        .then((/* response */) => {
          for (let lines of multiLines) {
            updateLine(lines);
          }
          return multiLines;
        });
    };

    actions.removeHanmakaLines = function (lines) {
      return actionVm.$backend
        .postJSon("/hanmaka/store/", {
          conditions: lines.conditions
        })
        .then((/* response */) => {
          let foundLines = findHanmkaLine(lines.conditions);
          if (foundLines) {
            let foundIndex = actionVm.$store.hanmakaLines.indexOf(foundLines);
            actionVm.$store.hanmakaLines.splice(foundIndex, 1);
          }
          return lines;
        });
    };

    actions.explainActionInReport = function (report, polisa, group) {
      return actionVm.$backend.postJson("/hanmaka/explain-polisa", {
        report,
        polisa,
        group
      });
    };

    actions.sendEmail = function (template, title, inputs) {
      return actionVm.$backend.postJson("/notifications/send-email", {
        template,
        title,
        inputs
      });
    };

    actions.sendSms = function (number, message) {
      return actionVm.$backend.postJson("/notifications/send-sms", {
        number,
        message
      });
    };

    actions.loadPendingNotifications = function () {
      return actionVm.$backend.getJson("/notifications/pending-notifications");
    };

    actions.notifyClientOnPublishedReport = function (clientId, reportId, notify) {
      return actionVm.$backend.postJson("/notifications/notify-published-report", {
        clientId,
        reportId,
        notify
      });
    };

    actions.sortActions = function (actions) {
      let priorityValue = {
        urgent: 6,
        important: 5,
        notice: 4,
        "non-urgent": 3,
        info: 2,
        low: 1,
        "": 0
      };
      return actions.sort((a, b) => {
        let aP = priorityValue[a.priority || ""] || 0;
        let bP = priorityValue[b.priority || ""] || 0;
        if (aP != bP) {
          return bP - aP;
        }
        if (a.endAt < b.endAt) {
          return -1;
        } else if (a.endAt > b.endA) {
          return 1;
        }
        return 0;
      });
    };

    actions.loadActions = function (query, options = {}) {
      return actionVm.$backend
        .getJson("/action", { params: query })
        .then((result) => {
          if (options.sort) {
            result = actions.sortActions(result);
          }
          return result;
        });
    };

    actions.updateAction = function (action) {
      return actionVm.$backend.postJson("/action/action", action);
    };

    actions.deleteAction = function (action) {
      return actionVm.$backend.deleteJson("/action/action/" + action.id);
    };

    actions.loadAudit = function (from, to) {
      return actionVm.$backend.getJson("/audit/events", {
        params: { from, to }
      });
    };

    actions.loadAuditSummary = function (from, to) {
      return actionVm.$backend.getJson("/audit/events/counts", {
        params: { from, to }
      });
    };

    actions.loadMessagesAudit = function (from, to) {
      return actionVm.$backend.getJson("/audit/events/messages", {
        params: { from, to }
      });
    };

    // is that been used?
    actions.loadSearchTags = function () {
      return actionVm.$backend.getJson("/json_index/tags");
    };

    // is that been used?
    actions.loadSearchTagValues = function (tag) {
      return actionVm.$backend.getJson("/json_index/tags/" + tag);
    };

    // is that been used?
    actions.loadSearchTagValuesWithFilters = function (tag, filters) {
      return actionVm.$backend.postJson("/json_index/tags/", { tag, filters });
    };

    actions.getHevrot = function () {
      return actionVm.$backend.getJson("/hevrot");
    };

    actions.getHevraKupot = function (hevra, month) {
      return actionVm.$backend.getJson(`/hevrot/${hevra}?month=${month}`);
    };

    actions.openDialog = function (setDialogState) {
      setDialogState(true);
      actionVm.$store.openDialogs.push(setDialogState);
    };

    actions.closeDialog = function () {
      if (actionVm.$store.openDialogs.length > 0) {
        let setDialogState = actionVm.$store.openDialogs.pop();
        setDialogState(false);
      }
    };

    actions.getMutzarOptions = function (params) {
      return actionVm.$backend.getJson("/maslul_options/mutzar", { params });
    };

    actions.getBituachOptions = function (params) {
      return actionVm.$backend.getJson("/maslul_options/bituach", { params });
    };

    // is that been used?
    actions.reloadCurrentClient = function () {
      let promises = [];
      let current = actionVm.$store.current;

      current.client = undefined;
      current.clientApi = actions.clients.client(current.clientId);
      promises.push(
        current.clientApi.load().then((client) => {
          current.client = client;
          return client;
        })
      );
      current.reportId = undefined;
      current.report = undefined;
      current.forms = undefined;
      current.analyzed = undefined;
      current.reportApi = undefined;

      return promises;
    };

    // is that been used?
    actions.reloadCurrentReport = function () {
      let promises = [];
      let current = actionVm.$store.current;

      current.reportApi = current.clientApi.report(current.reportId);
      promises.push(
        current.reportApi.loadReport().then((state) => {
          current.report = state.report;
          return current.reportApi
            .analyzeReport(current.report)
            .then((result) => {
              current.analyzed = result;
              return current.report;
            });
        })
      );

      promises.push(
        current.reportApi.loadForms().then((state) => {
          current.forms = state.forms;
          return actionVm.$store.forms;
        })
      );

      return promises;
    };

    // is that been used?
    actions.setCurrentClientAndReport = function (clientId, reportId) {
      let promises = [];
      let current = actionVm.$store.current;

      if (current.clientId != clientId) {
        current.clientId = clientId;
        promises.push(...actions.reloadCurrentClient());
      }

      if (current.reportId != reportId) {
        current.reportId = reportId;
        promises.push(...actions.reloadCurrentReport());
      }

      return Promise.all(promises).then(() => current);
    };

    actions.loadSystemInfo = function () {
      return actionVm.$backend.getJson("/audit/system");
    };

    actions.loadSystemActivity = function () {
      return actionVm.$backend.getJson("/audit/system/activity/email");
    };

    actions.loadVerifications = function () {
      return actionVm.$backend.getJson("/verifications");
    }

    actions.loadVerification = function (id) {
      return actionVm.$backend.getJson("/verifications/" + id);
    }

    actions.completeVerification = function (id, status) {
      return actionVm.$backend.postJson("/verifications/" + id, { status });
    }

    actions.archiveVerification = function (id) {
      return actionVm.$backend.postJson("/verifications/" + id + "/archive", {});
    }
  }
};
