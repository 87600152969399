import moment from "moment";
import validations from "@/utils/validations";

export default function install(Vue) {
  let storeModule = new Vue({
    data() {
      return {
        hash: {},

        info: {
          shem_prati: "",
          shem_mishpacha: "",
          mispar_zihuy_lakoach: "",
          cellular: "",
          email: ""
        },

        terms: false,
        mode: "buy", // "register", //"authorize", // "buy" // "authorizeMislaka"
        stepIndex: -1,

        product: "addedvalue",
        extendedSupport: false,
        years: 1,

        subscriptions: [],
        forms: {},
        need_id_photo: false,
        taarich_hanpaka_mispar_zihuy_lakoach: "",
        authurization_form: "client_fv/a-fill-2.pdf",

        assertions: {
          shem_prati: [validations.required, validations.alphaOnly],
          shem_mishpacha: [validations.required, validations.alphaOnly],
          mispar_zihuy_lakoach: [validations.required, validations.id],
          cellular: [validations.required, validations.mobile],
          email: [validations.required, validations.email]
        }
      };
    },

    watch: {
      info(newValue) {
        if (!this.taarich_hanpaka_mispar_zihuy_lakoach) {
          this.taarich_hanpaka_mispar_zihuy_lakoach =
            (newValue && newValue.taarich_hanpaka_mispar_zihuy_lakoach) || "";
        }
      }
    },

    computed: {
      detailsCompleted() {
        return (
          validations.assert(this.assertions, this.info) === true && this.terms
        );
      },

      fullname() {
        if (this.info.shem_prati && this.info.shem_mishpacha) {
          return `${this.info.shem_prati} ${this.info.shem_mishpacha}`;
        } else if (this.info.shem_prati) {
          return this.info.shem_prati;
        } else {
          return undefined;
        }
      },

      shouldShowProductSelection() {
        return this.mode == "buy";
      },

      steps() {
        if (this.mode == "register") {
          let steps = [
            {
              title: "פרטים אישיים",
              description: "הזנת פרטים האישיים, ת״ז ופרטי התקשרות.",
              completed: false,
              to: { name: "register-step-details" }
            },
            {
              title: "אימות זהותך",
              description:
                'ע"פ חוק עלינו לאמת את זהותך ולהבטיח שאכן אנו פונים עבורך לקבלת המידע.',
              completed: false,
              to: { name: "register-step-verify" }
            },
            {
              title: "אישור פנייה למסלקה הפנסיונית",
              description:
                "חתימה על טופס בקשת מידע המופנה למסלקה הפנסיונית המאשר את בקשתך לקבלת מידע.",
              completed: false,
              to: { name: "register-step-pdf-auth" }
            }
          ];
          if (this.need_id_photo) {
            steps.push(
              this.taarich_hanpaka_mispar_zihuy_lakoach
                ? {
                  title: "צילום ת.ז",
                  description: "נמצא העתק ת״ז שלך במערכת, לא נדרש צילום חדש.",
                  completed: true,
                  to: { name: "register-complete" }
                }
                : {
                  title: "צילום ת.ז",
                  description:
                    "על פי תקנות משרד האוצר, בפניה למסלקה עליך לצרף צילום ת״ז שלך עדכנית.",
                  completed: false,
                  to: { name: "register-step-upload-id" }
                }
            );
          }
          return steps;
        } else if (this.mode == "authorize") {
          let steps = [
            {
              title: "אימות זהותך",
              description:
                'ע"פ חוק עלינו לאמת את זהותך ולהבטיח שאכן אנו פונים עבורך לקבלת המידע.',
              completed: this.$account.auth,
              to: { name: "register-step-login" }
            },
            {
              title: "אישור פנייה למסלקה הפנסיונית",
              description:
                "חתימה על טופס בקשת מידע המופנה למסלקה הפנסיונית המאשר את בקשתך לקבלת מידע.",
              completed: false,
              to: { name: "register-step-pdf-auth" }
            }
          ];

          if (this.need_id_photo) {
            steps.push(
              this.taarich_hanpaka_mispar_zihuy_lakoach
                ? {
                  title: "צילום ת.ז",
                  description: "נמצא העתק ת״ז שלך במערכת, לא נדרש צילום חדש.",
                  completed: true,
                  to: { name: "register-complete" }
                }
                : {
                  title: "צילום ת.ז",
                  description:
                    "על פי תקנות משרד האוצר, בפניה למסלקה עליך לצרף צילום ת״ז שלך עדכנית.",
                  completed: false,
                  to: { name: "register-step-upload-id" }
                }
            );
          }
          return steps;
        } else if (this.mode == "authorizeMislaka") {
          let steps = [
            {
              title: "אימות זהותך",
              description:
                'ע"פ חוק עלינו לאמת את זהותך ולהבטיח שאכן אנו פונים עבורך לקבלת המידע.',
              completed: this.$account.auth,
              to: { name: "register-step-login" }
            },
            {
              title: "אישור פנייה למסלקה הפנסיונית",
              description: "יצירת בקשה למסלקה הפנסיונית לקבלת מידע חודשי.",
              completed: false,
              to: { name: "register-step-mislaka-authorize" }
            }
          ];

          if (this.need_id_photo) {
            steps.push(
              this.taarich_hanpaka_mispar_zihuy_lakoach
                ? {
                  title: "צילום ת.ז",
                  description: "נמצא העתק ת״ז שלך במערכת, לא נדרש צילום חדש.",
                  completed: true,
                  to: { name: "register-complete" }
                }
                : {
                  title: "צילום ת.ז",
                  description:
                    "על פי תקנות משרד האוצר, בפניה למסלקה עליך לצרף צילום ת״ז שלך עדכנית.",
                  completed: false,
                  to: { name: "register-step-upload-id" }
                }
            );
          }
          return steps;
        } else if (this.mode == "registerInvite") {
          let steps = [
            {
              title: "פרטים אישיים",
              description: "הזנת פרטים האישיים, ת״ז ופרטי התקשרות.",
              completed: false,
              to: { name: "register-step-details" }
            },
            {
              title: "אישור פנייה למסלקה הפנסיונית",
              description: "יצירת בקשה למסלקה הפנסיונית לקבלת מידע חודשי.",
              completed: false,
              to: { name: "register-step-mislaka-authorize" }
            }
          ];
          return steps;
        } else if (this.mode == "buy") {
          if (this.product == "intro" || this.product == "regular") {
            let steps = [
              {
                title: "הזנת פרטים אישיים",
                description: "שם, ת״ז ופרטי התקשרות.",
                completed: this.$account.auth,
                to: { name: "register-step-details" }
              },
              {
                title: "אימות זהותך ורכישת תוכנית",
                description:
                  'ע"פ חוק עלינו לאמת את זהותך ולהבטיח שאכן אנו פונים עבורך לקבלת המידע.',
                completed: this.$account.auth && !this.$account.needToBuySubscription,
                to: { name: "register-step-purchase" }
              },
              {
                title: "אישור פנייה למסלקה הפנסיונית",
                description: "יצירת בקשה למסלקה הפנסיונית לקבלת מידע חודשי.",
                completed: this.$account.auth && !this.$account.needToAuthorizeMislaka,
                to: { name: "register-step-mislaka-authorize" }
              }
            ];

            return steps;
          }
          if (this.product == "addedvalue") {
            let steps = [
              {
                title: "הזנת פרטים אישיים",
                description: "שם, ת״ז ופרטי התקשרות.",
                completed: this.$account.auth,
                to: { name: "register-step-details" }
              },
              {
                title: "מתן הראשה לבעל רישיון",
                description:
                  "חתימה על טופס מתן הראשה לבעל רישיון.",
                completed: false,
                to: { name: "register-step-b1-auth" },
                alternate: { name: "register-step-pdf-auth" }
              },
              {
                title: "צילום ת.ז",
                description:
                  "על פי תקנות משרד האוצר, בפניה למסלקה עליך לצרף צילום ת״ז שלך עדכנית.",
                completed: false,
                to: { name: "register-step-upload-id" }
              },
              {
                title: "אימות טפסים",
                description:
                  "אימות טפסים",
                completed: false,
                to: { name: "register-step-verify-forms" }
              },
              {
                title: "אישור פנייה למסלקה הפנסיונית",
                description: "יצירת בקשה למסלקה הפנסיונית לקבלת מידע חודשי.",
                completed: this.$account.auth && !this.$account.needToAuthorizeMislaka,
                to: { name: "register-step-mislaka-authorize" }
              }
            ];


            return steps;
          }
          return [];
        } else {
          return [];
        }
      },

      currentStep() {
        if (this.stepIndex >= 0) {
          return this.steps[this.stepIndex];
        } else {
          return undefined;
        }
      }
    },

    methods: {
      setMode(mode) {
        switch (mode) {
          case "register":
          case "authorize":
          case "authorizeMislaka":
          case "registerInvite":
          case "buy":
            this.mode = mode;
            break;
          default:
            throw new Error("unknown mode " + mode);
        }
      },

      reset() {
        this.info = {
          shem_prati: "",
          shem_mishpacha: "",
          mispar_zihuy_lakoach: "",
          cellular: "",
          email: ""
        };
        this.terms = false;
        this.mode = "buy";
        this.stepIndex = -1;
        this.product = "regular";
        this.extendedSupport = false;
        this.forms = {};
      },

      moveToNextStep() {
        this.stepIndex += 1;
      },

      async loadBuySubscriptions() {
        this.subscriptions = await this.$backend.getJson(
          "/account/register/options"
        );
      },

      fetchRegistrationDetailsFromHash() {
        if (window.location.hash.startsWith("#?")) {
          try {
            let registerInfo = {};
            let hash = window.location.hash.substring(2);
            const base64Regex = /^[A-Za-z0-9+/=]+$/;

            if (base64Regex.test(hash.replace("!", "=="))) {
              hash = window.atob(hash.replace("!", "=="));
            }
            hash
              .split("&")
              .map((kv) => kv.split("=", 2))
              .forEach((kv) => {
                let key = kv[0];
                let value = kv[1] ? decodeURIComponent(kv[1]) : true;
                registerInfo[key] = value;
              });

            this.hash = hash;

            //mislaka hash...
            if (registerInfo.identificationNumber || registerInfo.swiftnesskey) {
              //don't do anything... let authrizeMislaka figure this out
              return false;
            }

            this.mode = registerInfo.m || "buy";
            if (this.mode == "a") {
              this.mode = "authorize";
            } else if (this.mode == "m") {
              this.mode = "authorizeMislaka";
            } else if (this.mode == "r") {
              this.mode = "register";
            } else if (this.mode == "b") {
              this.mode = "buy";
            } else {
              // default to buy
              this.mode = "buy";
            }
            this.info.shem_prati = (registerInfo.sp || "").trim();
            this.info.shem_mishpacha = (registerInfo.sm || "").trim();
            this.info.mispar_zihuy_lakoach = (registerInfo.id || "").trim();
            this.info.cellular = (registerInfo.c || "").trim();
            this.info.email = (registerInfo.e || "").trim();
            this.need_id_photo = registerInfo.nid || false;
            this.taarich_hanpaka_mispar_zihuy_lakoach = (
              registerInfo.idd || ""
            ).trim();

            if (registerInfo.f == "a2p") {
              this.authurization_form = "client_fv/a-fill-2-plus.pdf";
            } else {
              this.authurization_form = "client_fv/a-fill-2.pdf";
            }

            if (registerInfo.p == "s" || registerInfo.p == "r") {
              this.product = "regular";
            } else if (registerInfo.p == "a") {
              this.product = "addedvalue";
            } else {
              this.product = "intro";
            }
            if (registerInfo.es == "1") {
              this.extendedSupport = true;
            } else {
              this.extendedSupport = false;
            }
            if (registerInfo.y == "1" || registerInfo.y == "2" || registerInfo.y == "3") {
              this.years = +registerInfo.y;
            }
            this.storeRegistartionState();
          } catch (error) {
            this.$tracing.captureException(error);
          }
          window.location.hash = "";
          return true;
        }
        return false;
      },
      storeRegistartionState() {
        window.sessionStorage.setItem('registration', JSON.stringify({
          info: this.info,
          mode: this.mode,
          need_id_photo: this.need_id_photo,
          authurization_form: this.authurization_form,
          taarich_hanpaka_mispar_zihuy_lakoach: this.taarich_hanpaka_mispar_zihuy_lakoach,
          extendedSupport: this.extendedSupport,
          product: this.product,
          years: this.years
        }))
      },
      loadRegistartionState() {
        try {
          let item = window.sessionStorage.getItem('registration');
          if (item) {
            let state = JSON.parse(item);
            if (state.info && state.mode) {
              this.info = state.info;
              this.need_id_photo = state.need_id_photo;
              this.authurization_form = state.authurization_form;
              this.taarich_hanpaka_mispar_zihuy_lakoach = state.taarich_hanpaka_mispar_zihuy_lakoach;
              this.extendedSupport = state.extendedSupport;
              this.product = state.product;
              this.years = state.years;
              this.setMode(state.mode);
              return true;
            }
          }
        } catch (error) {
          this.$tracing.captureException(error);
        }
        return false;
      },
      fetchRegistrationDetailsFromToken(router) {
        if (this.$account.auth) {
          let info = this.$account.info;

          this.info = {
            shem_prati: info.shem_prati,
            shem_mishpacha: info.shem_mishpacha,
            mispar_zihuy_lakoach: info.role == 'demo' ? '' : info.mispar_zihuy_lakoach,
            email: info.email ? info.email.trim() : "",
            cellular: info.cellular ? info.cellular.trim() : ""
          };

          if (info.role == 'demo') {
            this.setMode('registerInvite');
          }
          else {
            if (this.$account.needToBuySubscription) {
              this.setMode('buy');
              this.stepIndex = -1;
            } else if (this.$account.needToAuthorizeMislaka) {
              this.setMode('authorizeMislaka');
              this.stepIndex = -1;
            }
          }

          this.storeRegistartionState();

          return true;
        }
        return false;
      },
      selectProductToBuy(product) {
        this.product = product;
        if (this.product == "addedvalue") {
          this.authurization_form = "client_fv/b1-web.pdf";
        } else {
          this.authurization_form = "client_fv/a-fill-2.pdf";
        }
        this.storeRegistartionState();
      },
      generateForm(type) {
        let today = moment().format("DD/MM/YYYY");
        let todayFileSafe = moment().format("DD-MM-YYYY");
        let time = moment().format("hh:mm");
        let id = this.info.mispar_zihuy_lakoach;

        let fullname = this.fullname;

        if (type == "authorization") {
          if (this.authurization_form == "client_fv/b1-web.pdf") {
            return {
              display: "FutureValue טופס יפוי כוח ב1",
              filename: `ייפוי כח ב1-${fullname}-${todayFileSafe}.pdf`,
              summary:
                'על מנת שנוכל לספק לך שירות ללא עלות יש להגיש טופס זה חתום המקנה ליועץ אישור לייצג אותך מול חברות הניהול השונות.',
              icon: "fv",
              form: "client_fv/b1-web.pdf",
              fields: {
                "Check Box1": "Yes",
                "id_client": id, 
                "full_name": fullname, 
                "full_address":"", 
                "Check Box2": "Yes", 
                "Check Box3": "1", 
                "Check Box4": "1", 
                "#date(today,DD/MM/YYYY)": today,
                "date": today,
              },
              signatures: [
                {
                  "index": 10,
                  "name": "advisor_signature",
                  "rect": [153.673, 47.207, 260.208, 89.1563],
                  "appearance": "/Arial 10 Tf 0 g",
                  "type": "Text",
                  "flags": 268435456,
                  "justification": "center",
                  "page": 1
                },
                {
                  "index": 13,
                  "name": "client_signature",
                  "rect": [495.82, 49.982, 568.094, 89.3903],
                  "appearance": "/Arial 10 Tf 0 g",
                  "type": "Text",
                  "flags": 268435456,
                  "justification": "center",
                  "page": 1
                },
                {
                  "index": 8,
                  "name": "client_signature",
                  "rect": [405.982, 320.491, 514.957, 358.814],
                  "appearance": "/Arial 10 Tf 0 g",
                  "type": "Text",
                  "flags": 268435456,
                  "justification": "center",
                  "page": 2
                },
                {
                  "index": 10,
                  "name": "advisor_signature",
                  "rect": [262.078, 266.756, 370.205, 307.259],
                  "appearance": "/Arial 10 Tf 0 g",
                  "type": "Text",
                  "flags": 268435456,
                  "justification": "center",
                  "page": 2
                }
              ]
            };
          }
          if (this.authurization_form == "client_fv/a-fill-2-plus.pdf") {
            return {
              display: "FutureValue - טופס נספח א מתמשך",
              filename: `נספח א מתמשך-${fullname}-${todayFileSafe}.pdf`,
              summary:
                'ע"מ לקבל את נתוני התוכניות הפנסיוניות שלך מהמסלקה הפנסיונית\nיש להגיש טופס זה חתום המקנה ליועץ אישור קבלת מידע.',
              icon: "fv",
              form: "client_fv/a-fill-2-plus.pdf",
              fields: {
                id_client: id,
                full_name: fullname,
                taagid_name: "אופיר שץ",
                taagid_no: "03839202-3",
                "Check-1": "Yes",
                taagid_mail: "ofir@futurevalue.co.il",
                taagid_phone: "050-222-8000",
                "#date(today,DD/MM/YYYY)": today,
                "Check Box2": "Yes",
                "Check Box4": "1"
              },
              signatures: [
                {
                  index: 11,
                  name: "client_signature",
                  rect: [348.204, 447.565, 469.842, 486.978],
                  appearance: "/Arial 10 Tf 0 g",
                  type: "Text",
                  flags: 268435456,
                  justification: "center",
                  page: 1
                },
                {
                  index: 16,
                  name: "client_signature",
                  rect: [366.415, 79.5801, 488.054, 118.993],
                  appearance: "/Arial 10 Tf 0 g",
                  type: "Text",
                  flags: 268435456,
                  justification: "center",
                  page: 1
                },
                {
                  index: 5,
                  name: "client_signature",
                  rect: [380.864, 319.889, 489.412, 355.363],
                  appearance: "/Arial 10 Tf 0 g",
                  type: "Text",
                  flags: 268435456,
                  justification: "center",
                  page: 2
                }
              ]
            };
          }

          return {
            display: "FutureValue - טופס נספח א",
            filename: `נספח א-${fullname}-${todayFileSafe}.pdf`,
            summary:
              'ע"מ לקבל את נתוני התוכניות הפנסיוניות שלך מהמסלקה הפנסיונית\nיש להגיש טופס זה חתום המקנה ליועץ אישור קבלת מידע.',
            icon: "fv",
            form: this.authurization_form,
            fields: {
              id_client: id,
              full_name: fullname,
              taagid_name: "אופיר שץ",
              taagid_no: "038392023",
              "Check-1": "Yes",
              taagid_mail: "ofir@futurevalue.co.il",
              taagid_phone: "050-222-8000",
              "#date(today,DD/MM/YYYY)": today
            },
            signatures: [
              {
                index: 11,
                name: "client_signature",
                rect: [348.204, 447.565, 469.842, 486.978],
                appearance: "/Arial 10 Tf 0 g",
                type: "Text",
                flags: 268435456,
                justification: "center",
                page: 1
              },
              {
                index: 16,
                name: "client_signature",
                rect: [366.415, 79.5801, 488.054, 118.993],
                appearance: "/Arial 10 Tf 0 g",
                type: "Text",
                flags: 268435456,
                justification: "center",
                page: 1
              }
            ]
          };
        }

        if (type == "old_id") {
          return {
            display: "FutureValue - העלאת צילום ת.ז",
            filename: `צילום ת.ז.-${fullname}-${todayFileSafe}.pdf`,
            icon: "fv",
            form: "client_fv/image_upload_id_old.pdf",
            fields: { full_name: fullname, id_client: id },
            signatures: [
              {
                index: 0,
                name: "image_upload_front",
                rect: [86.2417, 438.187, 518.166, 720.777],
                appearance: "/Helv 12 Tf 0 g",
                type: "Text",
                justification: "left",
                page: 1
              },
              {
                index: 1,
                name: "image_upload_back",
                rect: [87.5302, 35.6503, 519.454, 318.04],
                appearance: "/Helv 12 Tf 0 g",
                type: "Text",
                justification: "left",
                page: 1
              }
            ]
          };
        }

        if (type == "bio_id") {
          return {
            display: "FutureValue - העלאת צילום ת.ז ביומטרית",
            filename: `צילום ת.ז. ביומטרית-${fullname}-${todayFileSafe}.pdf`,
            icon: "fv",
            form: "client_fv/image_upload_bio.pdf",
            fields: { full_name: fullname, id_client: id },
            signatures: [
              {
                index: 0,
                name: "image_upload_front",
                rect: [86.2417, 438.187, 518.166, 720.777],
                appearance: "/Helv 12 Tf 0 g",
                type: "Text",
                justification: "left",
                page: 1
              },
              {
                index: 1,
                name: "image_upload_back",
                rect: [87.5302, 35.6503, 519.454, 318.04],
                appearance: "/Helv 12 Tf 0 g",
                type: "Text",
                justification: "left",
                page: 1
              }
            ]
          };
        }

        if (type == "credit_check") {
          return {
            display: "FutureValue - אימות לקוח",
            filename: `אימות-לקוח-${fullname}-${todayFileSafe}.pdf`,
            icon: "fv",
            form: "client_fv/credit-card-validation.pdf",
            fields: {
              full_name: fullname,
              id_client: id,
              "#date(today,DD/MM/YYYY)": today,
              time: time,
              credit_card_no: "XXXX",
              transaction_confirmation_no: "XXXX",
              credit_card_type: "XXXX"
            },
            signatures: []
          };
        }

        if (type == "credit_pay") {
          return {
            display: "FutureValue - תשלום כרטיס אשראי",
            filename: `תשלום-כרטיס-אשראי-${fullname}-${todayFileSafe}.pdf`,
            icon: "fv",
            form: "client_fv/credit-card-pay.pdf",
            fields: {
              full_name: fullname,
              id_client: id,
              "#date(today,DD/MM/YYYY)": today,
              time: time,
              credit_card_no: "XXXX",
              transaction_confirmation_no: "XXXX",
              credit_card_type: "XXXX",
              product: "XXXX",
              charge_amount: "XXXX",
              transaction_type: "XXXX"
            },
            signatures: []
          };
        }
      },

      hasFormsToCommit() {
        return Object.keys(this.forms).length > 0;
      },

      commitRegistration() {
        let today = moment().format("YYYY-MM-DD");

        let info = {
          ...this.info,
          taarich_nechonut: today,
          description: "" //reset description
        };

        if (this.mode == "register") {
          info.title = "אימות לקוח ורישום במערכת";
        } else {
          info.title = "אישור בקשת קבלת נתונים מעודכנים ללקוח מהמסלקה";
        }

        let forms = {
          general: Object.values(this.forms),
          people: [
            {
              shem_prati: this.info.shem_prati,
              shem_mishpacha: this.info.shem_mishpacha,
              relation: "לקוח",
              id: this.info.mispar_zihuy_lakoach,
              signatures: {
                count: 2,
                signed: 2,
                names: ["client_signature"]
              },
              needed: true
            }
          ]
        };

        return this.$backend.postJson("account/register", { info, forms });
      },

      haveAllRequieredFormsForVerification() {
        //we only do verification for addedvalue product
        if (this.product != "addedvalue") {
          return true;
        }
        //no id
        if (!this.forms["client_fv/image_upload_id_old.pdf"] && !this.forms["client_fv/image_upload_bio.pdf"]) {
          return false;
        }
        //no b1
        if (!this.forms["client_fv/b1-web.pdf"]) {
          return false;
        }
        return true;
      },

      async startFormsVerification() {
        let today = moment().format("YYYY-MM-DD");
        let host = window.location.origin;

        let info = {
          ...this.info,
          taarich_nechonut: today,
          title: "אימות לקוח ורישום במערכת",
          description: "" //reset description
        };

        let forms = {
          general: Object.values(this.forms),
          people: [
            {
              shem_prati: this.info.shem_prati,
              shem_mishpacha: this.info.shem_mishpacha,
              relation: "לקוח",
              id: this.info.mispar_zihuy_lakoach,
              signatures: {
                count: 2,
                signed: 2,
                names: ["client_signature"]
              },
              needed: true
            }
          ]
        };

        let body = {
          info,
          forms,
          product: this.product,
          host
        };
        let response = await this.$backend.postJson("account/register/start2", body);        
        if (response.status == 'verification') {
          this.verification = response;
        }
        return response;
      },

      async completeFormsVerification() {
        let response = await this.$backend.postJson("account/register/complete2", {});
        if (response.status == 'registered') {
          //forms where successfully uploaded, we can remove reference from the correct verification
          this.forms = {};
          this.verification = undefined;
        }
        else if (response.status == 'error') {
          //forget the current registeration request, but keep the forms in memory
          this.verification = undefined;
        }
        else if (response.status == 'verification') {
          if (response.verification_status == 'rejected') {
            //require the user to reupload the forms.
            this.forms = {};
            this.verification = undefined;
          }
        }
        return response;
      }
    }
  });

  return storeModule;
}
